<template>
	<lidaPage :columns="columns" :query="purchaseReceiptPage" :formColumns="formColumns" subTitle="资金列表">
		<template #paidAmount="scope">
			<div>￥{{scope.row.paidAmount}}</div>
		</template>
	</lidaPage>
</template>

<script>
	import { mapActions } from 'vuex'
	export default{
		data(){
			return{
				columns:[
					{label:'收款时间',prop:'collectionTime'},
					{label:'买方名称',prop:'buyerName'},
					{label:'买方企业名称',prop:'buyerEnterpriseName'},
					{label:'卖方名称',prop:'sellerName'},
					{label:'卖方企业名称',prop:'sellerEnterpriseName'},
					{label:'已支付金额',prop:'paidAmount'},
				],
				formColumns:[
					{type:'date',prop:'time',label:'收款日期'},
					{type:'input',prop:'wd',label:'综合搜索',placeholder:'请输入名字、企业等关键字'},
				],
			}
		},
		methods:{
			...mapActions('mysaleMudule',[
				'purchaseReceiptPage',  //获取用户资金列表
			]),
		},
	}
</script>

<style scoped lang="less">
	
</style>
